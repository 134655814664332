.container { 
  color:#FFFFFF;
  font-family: Gilroy;
  font-weight: 700;
  // @media (max-width: 500px) {
  //   transform: scale(0.7); 
  // }
}

h2 {
  font-family: Gilroy;
  font-weight: 900;
  font-size: 32px;
  text-align: center;
  margin: 30px;
  
}

.label {
  position: absolute;
  background: black;
  padding: 2px 0 2px 4px;
  margin-left: 12px;
  width: 120px;
  font-size: 12px;
}

input[type="text"], input[type="email"] {
  width: 100%;
  padding: 20px 20px;
  margin: 8px 0;
  border-radius: 12px;
  background: transparent;
  border: 2px solid #FFFFFF;
  font-family: Gilroy;
  font-weight: 700;
}

input[type="text"]::placeholder, input[type="email"]::placeholder {
  color: #FFFFFF
}

input[type="text"]:focus, input[type="email"]:focus {
  outline: none;
  border: 2px solid black;
}

input:not(:focus):invalid {
  border: 2px solid red;
}

input:not(:focus):valid {
  border-color: white;
}

input[type="submit"] {
  width: 100%;
  background: transparent;
  border: 2px solid #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  margin-top: 30px;
  font-weight: 900;
  font-size: 24px;
  transition: all ease .2s;
}

input[type="submit"]:hover {
  border: 2px solid black;
  cursor: pointer;
}

.optIns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.plusOne {
  width: 100%;
}

.switch {
  position: absolute;
  right: 50px;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.sliderRound {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #FFFFFF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.sliderRound:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #BDBEEF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .sliderRound {
  background-color: #BDBEEF;
}

input:checked + .sliderRound:before {
  background: white;
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.successHeader {
  margin-bottom: 150px;
}

.successBody {
  text-align: center;
  font-size: 32px;
  margin: auto 20px;
  @media (max-width: 650px) {
   margin: 0; 
   font-size: 16px;
  }
  a {
    text-decoration: underline;
  }
}

.successBodyLast {
  margin-bottom: 80px;
}
