.resize {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  z-index: 9000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.resizeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  max-width: 370px;
  text-align: center;

  h5,
  h6 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
  }

  h5 {
    font-size: 24px;
  }

  h6 {
    font-size: 18px;
  }
}

.container {
  background: linear-gradient(rgba(0, 0, 0, 0), black 90%),
  linear-gradient(90deg, #bdbff8 0%, #ffecc3 100%);
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.main {
  position: absolute;
  overflow: hidden;
  top: 10%;
  bottom: 82px;
  width: 100%;
  z-index: 40;
}

.headerContainer {
  z-index:30;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 37px 0;

  @media (max-width: 500px) {
    padding: 33px 0;
  }

  @media (max-height: 850px) {
    padding: 19px 0;
  }

  img {
    position: fixed;
    left: 34px;
    top: 11px;
  }
}

.headerText {
  font-family: Houstone;
  font-style: normal;
  font-weight: 600;
  font-size: 94px;
  line-height: 108px;
  position: relative;
  text-align: center;
  letter-spacing: 0.135em;
  text-transform: uppercase;
  color: #ffffff;

  @media (max-width: 937px) {
    margin-top: 0rem;
  }
  @media (max-width: 580px), (max-height: 850px) {
    font-size: 55px;
    line-height: 45px;
  }
  @media (max-width: 780px) {
    font-size: 58px;
    line-height: 45px;
  }
  @media (max-width: 580px) {
    font-size: 38px;
    line-height: 45px;
  }
}

.glitch:after, .glitch:before {
  position: absolute;
  width: 100%;
  content: attr(data-text);
  clip: rect(0, 900px, 0, 0);
}
.glitch:after {
  animation: glitch-anim 2s infinite linear alternate-reverse;
  left: 1px;
  text-shadow: 1px 0 black;
}
.glitch:before {
  animation: glitch-anim-2 3s infinite linear alternate-reverse;
  left: -1px;
  text-shadow: -1px 0 black;
}
@keyframes glitch-anim {
  0% { clip: rect(3px, 9999px, 93px, 0); }
  5% { clip: rect(53px, 9999px, 78px, 0); }
  10% { clip: rect(10px, 9999px, 75px, 0); }
  15% { clip: rect(32px, 9999px, 40px, 0); }
  20% { clip: rect(65px, 9999px, 62px, 0); }
  25% { clip: rect(31px, 9999px, 14px, 0); }
  30% { clip: rect(94px, 9999px, 87px, 0); }
  35% { clip: rect(81px, 9999px, 41px, 0); }
  40% { clip: rect(45px, 9999px, 50px, 0); }
  45% { clip: rect(82px, 9999px, 41px, 0); }
  50% { clip: rect(71px, 9999px, 3px, 0); }
  55% { clip: rect(75px, 9999px, 60px, 0); }
  60% { clip: rect(20px, 9999px, 49px, 0); }
  65% { clip: rect(67px, 9999px, 92px, 0); }
  70% { clip: rect(47px, 9999px, 55px, 0); }
  75% { clip: rect(63px, 9999px, 90px, 0); }
  80% { clip: rect(70px, 9999px, 92px, 0); }
  85% { clip: rect(41px, 9999px, 60px, 0); }
  90% { clip: rect(56px, 9999px, 79px, 0); }
  95% { clip: rect(21px, 9999px, 68px, 0); }
  100% { clip: rect(15px, 9999px, 72px, 0); }
}

@keyframes glitch-anim-2 {
  0% { clip: rect(65px, 9999px, 99px, 0); }
  5% { clip: rect(86px, 9999px, 70px, 0); }
  10% { clip: rect(79px, 9999px, 60px, 0); }
  15% { clip: rect(15px, 9999px, 88px, 0); }
  20% { clip: rect(24px, 9999px, 5px, 0); }
  25% { clip: rect(35px, 9999px, 3px, 0); }
  30% { clip: rect(56px, 9999px, 11px, 0); }
  35% { clip: rect(2px, 9999px, 38px, 0); }
  40% { clip: rect(60px, 9999px, 50px, 0); }
  45% { clip: rect(27px, 9999px, 4px, 0); }
  50% { clip: rect(79px, 9999px, 12px, 0); }
  55% { clip: rect(23px, 9999px, 8px, 0); }
  60% { clip: rect(65px, 9999px, 55px, 0); }
  65% { clip: rect(19px, 9999px, 7px, 0); }
  70% { clip: rect(43px, 9999px, 17px, 0); }
  75% { clip: rect(65px, 9999px, 91px, 0); }
  80% { clip: rect(45px, 9999px, 66px, 0); }
  85% { clip: rect(3px, 9999px, 2px, 0); }
  90% { clip: rect(58px, 9999px, 81px, 0); }
  95% { clip: rect(29px, 9999px, 20px, 0); }
  100% { clip: rect(82px, 9999px, 28px, 0); }
}

