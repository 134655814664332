@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: local("Gilroy"),
    url("../src/fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: local("Gilroy"),
    url("../src/fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: local("Gilroy"),
    url("../src/fonts/Gilroy-Black.ttf") format("truetype");
}

:root {
  --regular-font: "Gilroy";
}

@font-face {
  font-family: Houstone;
  src: url(./fonts/Houstone.woff2);
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;900&display=swap");
