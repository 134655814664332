.container {
  display: flex;
  position: absolute;
  width: 100%;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo {
    width: 82px;
    height: 82px;

    @media (max-width: 490px) {
    width: 50px;
    height:50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

    &:first-of-type {
      margin-right: 1rem;
    }
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: right;
    color: #ffffff;
    @media (max-width: 410px) {
      text-align: center;
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 0.5rem;
  }

  .copyBox {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 555px) {
      align-items: center;
      height: 50px;
    }

    p {
      @media (max-width: 740px) {
        font-size: 0.9rem;
      }
    }
  }
}
