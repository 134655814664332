.container {
  height: 100%;
  @media (max-width: 500px) {
    padding-bottom: 10%;
    transform: scale(0.75);
  }
  @media (max-height: 800px) {
    transform: scale(0.75); 
  }
}

.mintText {
  z-index: 4;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  position: fixed;
  left: 0;
  right: 0;
}

.imgBox {
  width: auto;
  height: auto;
  object-fit: scale-down;
  margin-bottom: 5vh;
}

.textBox {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 500px;
  max-width: 800px;

  .textBold {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 45px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    margin-bottom: 0.75rem;
    text-transform: uppercase;

    color: #ffffff;

    @media (max-width: 490px) {
      font-size: 43px;
      line-height: 40px;
    }
  }

  .subText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;

    color: #ffffff;

    @media (max-width: 490px) {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 0.5rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }
  }
}

.connectButton {
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 11.1213px;
  width: 358px;
  height: 74px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  font-size: 27.8034px;
  line-height: 28px;
  color: white;
  /* or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  transition: all 0.1s ease-out;

  @media (max-width: 490px) {
    width: 323px;
    height: 61px;
  }

  &:hover {
    background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
  }
}

.connectButton2 {
  border: 1.04263px solid rgba(255, 255, 255, 0.25);
  box-sizing: border-box;
  border-radius: 11.1213px;
  width: 358px;
  height: 74px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 27.8034px;
  line-height: 28px;
  color: white;
  /* or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  transition: all 0.1s ease-out;

  @media (max-width: 490px) {
    width: 323px;
    height: 61px;
  }

  &:hover {
    box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(-2px);
    // background: #ffe395;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}

.modalHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #1b1c2a;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.errorMessage {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: red;
}

.awaitingText {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #1b1b1b;
}