.container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: fixed;
  bottom: 132px;
  left: 0;
  right: 0;
  font-weight: 900px;
  font-family: Gilroy;

  @media (max-width: 500px) {
    padding-bottom: 10%;
  }
}
.errorText {
  z-index: 4;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 15px;
  color: red;
}

.innerContainer {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1080px;
}

.textContainer {
  height: 100%;
  width: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  h2 {
    text-shadow: 0px 0px 20px #000000;
  }

  @media (max-width: 690px) {
    padding-bottom: 0rem;
  }

  .totalSupplyBoxGrid {
    position: absolute;
    bottom: 27px;
    right: 0;
    font-style: normal;
    font-size: 20.8929px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.13em;
    color: #ffffff;

    @media (max-width: 730px) {
      bottom: 7px;
      left: calc(50% - 260px);
      font-size: 14.8929px;
    }
    @media (max-width: 500px) {
      bottom: -56px;
      left: calc(50% - 201px);
      font-size: 14.8929px;
    }

    p {
      line-height: 1.3;

      @media (max-width: 730px) {
        line-height: 1rem;
      }
      @media (max-width: 500px) {
        font-size: 13px;
        line-height: 1rem;
      }
    }
  }

  .dynamicMintTextBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
  }

  .dynamicMintText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
  }

  h2 {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900px;
    font-size: 60px;
    line-height: 69px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.095em;
    color: #ffffff;
    margin: 4.2rem auto 1.625rem;

    @media (max-width: 780px) {
      margin-top: 6rem;
      font-size: 37px;
      line-height: 42px;
    }

    @media (max-width: 580px) {
      margin-top: 5rem;
      font-size: 30px;
      line-height: 35px;
    }

    @media (max-width: 500px) {
      margin-top: 0rem;
      font-size: 30px;
      line-height: 35px;
      margin-bottom: 0.8rem;
    }
  }

  .header {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 80px;
    line-height: 80px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }

  .buttonText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 145.52%;
    letter-spacing: 0.075em;
    text-transform: capitalize;
    color: #fff;
  }

  .buttonSubText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }

  .wltext {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #ffffff;
    margin: 1rem 0;
  }

  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

.button {
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 11.1213px;
  width: 358px;
  height: 74px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: normal;
  font-size: 27.8034px;
  line-height: 28px;
  color: white;
  /* or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  transition: all 0.1s ease-out;

  &:disabled {
    cursor: not-allowed;
    &:hover {
      transform: translateY(0px);
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.8);
      background: transparent;
    }
    p {
      color: rgb(122, 122, 122);
    }
  }

  @media (max-width: 490px) {
    width: 323px;
    height: 61px;
  }

  &:hover {
    border: 2px solid white;
    background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }
}

.scrollBox {
  height: 500px;
  min-height: 100px;
  background-color: rgb(0, 0, 0, 0.5);
  overflow-y: scroll;
  border-radius: 10px;
  margin: 0 1.25rem 1.25rem;
  scrollbar-width: none;
  margin-top: 300px;
}

.scrollBoxInfo {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1.25rem;

  @media (max-width: 780px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.gridBox {
  // height: 100%;
  display: grid;
  gap: 2rem;
  // height: 584px;
  color: white;
  min-height: 100px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 2rem;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 700px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  > div {
    display: flex;
    img {
      padding: 1rem;
      // padding-bottom: 0.5rem;
    }
  }

  @media (max-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media (max-width: 867px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 659px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 452px) {
    grid-template-columns: 1fr;
  }
}

.gridCard {
  background: rgba(91, 91, 91, 0.5);
  box-shadow: 0px 2.40691px 1.92553px rgba(0, 0, 0, 0.25);
  border-radius: 4.81383px;
  margin: auto;
  cursor: pointer;
  @media (min-width: 452px) {
    &:hover {
      background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
    }
  }

  p {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 14px;
    line-height: 11px;
    /* identical to box height */
    text-align: left;
    padding-bottom: 0.5rem;
    padding-left: 1rem;

    color: rgba(255, 255, 255, 0.75);
  }
}

.hover {
  background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
}

.mintButtonContainer {
  color: white;

  margin: 0 6px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 730px) {
    margin: 0 6px 54px;
  }

  @media (max-width: 500px) {
    margin: 0px 0px 0px;
  }

  .mintSectionText {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 40px;

    color: #ffffff;

    @media (max-width: 500px) {
      font-size: 21px;
      line-height: 1.6rem;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.buttonBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  @media (max-width: 500px) {
    margin: 0 1.25rem;
  }

  .innerBox {
    width: 80px;
    height: 72px;
    left: 1px;
    top: 2px;
    margin-right: 0.75rem;
    border-radius: 4.81383px;
    transition: all 0.1s ease-out;

    > div {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .bigNum {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 900;
    font-size: 50.566px;
    line-height: 51px;
    /* or 100% */

    text-align: center;
    text-transform: uppercase;

    color: #ffffff;
  }
}

.zero {
  background: #5b5b5b;
}

.one {
  background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
}
