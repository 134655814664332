.wholePageDark {
  backdrop-filter: blur(10px);
  position: fixed;
  z-index: 40;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000b2;
}

.container {
  backdrop-filter: blur(10px);
  font-family: Gilroy;
  font-weight: 900;
  max-width: 1066px;
  height: 608px;
  margin: auto 1.25rem;
  max-height: 100%;
  position: fixed;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
  border-radius: 40px;
  padding: 2rem;

  .closeButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 21px;
    cursor: pointer;
    height: 54px;
    width: 54px;
    border-radius: 27px;
    transition: transform 0.1s ease-out;

    &:hover {
      border: 2px solid white;
    }
  }

  h3 {
    margin: 0.6rem 0;
    font-style: normal;
    font-weight: 900;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    color: white;
  }

  p {
    color: white;
  }

  .sectionTop {
    .awaitingText {
      margin: 0.6rem 0;
      z-index: 3;
      font-style: normal;
      font-weight: 900;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      color: white;
      justify-self: center;
    }

    p {
      font-style: normal;
      font-weight: 900;
      font-size: 34px;
      text-align: center;
      color: white;
    }
  }

  .sectionBottom {
    align-self: flex-end;
    justify-self: center;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.connectButton {
  border: 2px solid white;
  box-sizing: border-box;
  border-radius: 11.1213px;
  width: 358px;
  height: 74px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 900;
  font-size: 27.8034px;
  line-height: 28px;
  /* or 100% */

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  color: #ffffff;
  transition: all 0.1s ease-out;
  margin: 0.7rem;

  @media (max-width: 890px) {
    width: 346px;
    height: 67px;
    margin: 0.5rem;
  }

  @media (max-width: 852px) {
    margin: 0.4rem;
  }

  @media (max-width: 852px) {
    width: 323px;
    height: 61px;
  }

  @media (max-width: 786px) {
    width: 258px;
    height: 55px;
  }

  &:hover {
    border: 2px solid black;
  }

  &:active {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.8);
    transform: translateY(1px);
  }

  &:disabled {
    box-shadow: none;
    background-color: #ffeab0;
    cursor: not-allowed;
    color: grey;

    &:hover {
      box-shadow: none;
      transform: none;
      background-color: #ffeab0;
      cursor: not-allowed;
      color: grey;
    }
  }
}
