.wholePageDark {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000b2;
  // background: linear-gradient(rgba(0, 0, 0, 0), black 90%);
  // linear-gradient(90deg, #bdbff8 0%, #ffecc3 100%);
  backdrop-filter: blur(12px);
}

.container {
  max-width: 1050px;
  position: fixed;
  margin: 5px;
  z-index: 3;
  background: linear-gradient(181.28deg, #B9BBF1 -8.31%, #FAE7C1 198.08%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  background-position: center;
  background-size: cover;
  padding: 2rem;
   @media (max-height: 650px) {
     transform: scale(0.8)
   }
   @media (max-height: 500px) {
     transform: scale(0.6);
   }

  .closeButtonContainer {
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    top: 21px;
    cursor: pointer;
    height: 54px;
    width: 54px;
    border-radius: 27px;
    transition: transform 0.1s ease-out;

    &:hover {
      border: 2px solid white;
    }
  }
}